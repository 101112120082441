<template>
  <router-view />
  <Toast v-for="t in toastQueue" :key="t.id" :options="t" />
</template>

<script>
import Toast from '@/components/Toast.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BlakeEatsPaint',
  components: {
    Toast
  },
  setup() {
    const store = useStore();

    //Computed
    const toastQueue = computed(() => store.state.toastQueue);

    return {
      toastQueue
    }
  }
}
</script>

<style lang="scss">
</style>
