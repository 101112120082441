<template>
  <div class="gallery mx-md">
    <TimeFrame type="gallery" :timeFrame="galleryTimeFrame" />
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import TimeFrame from '@/components/TimeFrame';
export default {
  name: 'Gallery',
  components: {
    TimeFrame
  },
  setup() {
    const store = useStore();

    //Methods
    const getProjects = async () => {
      await store.dispatch('getProjects');
    };

    //Computed
    const allProjects = computed(() => {
      return store.state.projects || [];
    });
    const galleryTimeFrame = computed(() => {
      let timeFrame = [];
      if(allProjects.value.length > 0){
        allProjects.value.forEach(project => {
          if(project.hidden != 'true'){
            const hasOriginDateIndex = timeFrame.findIndex(tp => tp.date == new Date(project.origin_date).getFullYear());
            if(hasOriginDateIndex < 0){
              timeFrame.push({date: new Date(project.origin_date).getFullYear(), projects: [project]});
            } else {
              timeFrame[hasOriginDateIndex]?.projects.push(project);
            }
          }
        });
      }

      // Sort the main timeFrame array by date in descending order (newest first)
      timeFrame.sort((a, b) => b.date - a.date);

      // Sort each year's projects array by origin_date in descending order (newest first)
      timeFrame.forEach(timeFrameEntry => {
        timeFrameEntry.projects.sort((a, b) => new Date(b.origin_date) - new Date(a.origin_date));
      });

      return timeFrame;
    });

    //Lifecycle Hooks
    onMounted(() => {
      getProjects();
    });


    return {
      //Computed
      allProjects,
      galleryTimeFrame,
      //Methods
      getProjects
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
