<template>
  <div class="pb-md pt-xl">
    <h1 class="mb-sm mt-none">Blake Bless</h1>
    <a target="_blank" href="https://linktr.ee/blakeeatspaint">@blakeeatspaint</a>
    <div class="row full-width items-center justify-center">
      <div class="mt-sm mr-md cursor-pointer" :class="{ 'text-positive' : mode == 'gallery' }" @click="setMode('gallery')">Gallery</div>
      <!-- <div class="mt-sm mx-md cursor-pointer" :class="{ 'text-positive' : mode == 'portfolio' }" @click="setMode('portfolio')">Portfolio</div> -->
      <div class="mt-sm cursor-pointer" :class="{ 'text-positive' : mode == 'contact' }" @click="setMode('contact')">Contact Me</div>
    </div>
  </div>
  <Gallery v-if="mode == 'gallery'" />
  <Portfolio v-if="mode == 'portfolio'" />
  <ContactForm v-if="mode == 'contact'" />
</template>

<script>
import { ref } from 'vue';
import Gallery from '@/components/Gallery.vue';
import ContactForm from '@/components/ContactForm.vue';
import Portfolio from '@/components/Portfolio.vue';

export default {
  name: 'Home',
  components: {
    Gallery,
    ContactForm,
    Portfolio
  },
  setup() {
    //Data
    const mode = ref("gallery");

    //Methods
    const setMode = (newMode) => {
      mode.value = newMode;
    }

    return {
      mode,
      //Methods
      setMode,
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
}
</style>
