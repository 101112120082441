<template>
  <div class="portfolio mx-md">
    <TimeFrame type="portfolio" />
  </div>
</template>

<script>
import TimeFrame from '@/components/TimeFrame';
export default {
  name: 'Portfolio',
  components: {
    TimeFrame
  },
  setup() {

    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
