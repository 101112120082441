import { createApp } from 'vue';
import { toast } from '@/events/toast.js';
import App from './App.vue';
import '@/styles/_index.scss';
import blakesStore from '@/store/blakesStore.js';
import router from './router';
import axios from 'axios';

//Dev Env
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
//Production Env (make sure this is uncommented when building)
axios.defaults.baseURL = 'https://api.blakeeatspaint.com';

const app = createApp(App);
app.config.globalProperties.toast = toast;
app.use(blakesStore).use(router).mount('#app');
