import store from '../store/blakesStore';
let toastCount = 0;

let newMessage = (options) => {
  const toastId = toastCount;

  options.id = toastId;
  store.dispatch('addToastMessage', options);

  toastCount++;

  setTimeout(() => {
    store.dispatch('removeToastMessage', toastId);
  }, options.timeout || 5000);
}

const toast = { newMessage };

export { toast };